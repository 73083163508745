@import '../../../styles/variables.scss';

.error {
    height: 700px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    &__text {
        color: $main-color;
        font-size: 40px;
    }
    &__link {
        display: block;
        margin-top: 30px;
        color: $grey;
    }
}