@import '../../styles/variables.scss';

.char {
    &__search {
        &-form {
            padding: 25px;
            margin-top: 30px;
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
            position: relative;
            z-index: 5;
            background-color: #fff;
        }
        &-label {
            display: block;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            color: #000;
        }
        &-wrapper {
            margin-top: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            input {
                padding: 0 10px;
                width: 250px;
                height: 38px;
                border: none;
                background: #FFFFFF;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                font-size: 14px;
            }
        }
        &-error {
            margin-top: 25px;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            color: $main-color;
        }
        &-critical-error {
            margin-top: 25px;
        }
        &-success {
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            color: #03710E;
        }
    }
}